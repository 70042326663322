import queryString from 'query-string';
import httpClient from '../httpClient';

const apiUrl = process.env.REACT_APP_API_URL;

export const dataProvider = {
    fetchSource: async (sourceUrl) => {
        const url = `${apiUrl}/sources/fetch?query=${sourceUrl}`;
        return httpClient(url, {
            method: 'post'
        })
            .then(({ json }) => ({
                data: { ...json.data.resource }
            }))
            .catch((error) => {});
    },

    getApplicationSettings: async (shortname) => {
        const url = `${apiUrl}/settings?shortname=${shortname}`;
        return httpClient(url, { method: 'post' }, false)
            .then(({ json }) => ({
                data: { ...json.data.resource }
            }))
            .catch((error) => {});
    },

    getSsoSettings: async (domain) => {
        const url = `${apiUrl}/sso?domain=${domain}`;
        return httpClient(url, { method: 'get' }, false)
            .then(({ json }) => ({
                data: { ...json.data.resource }
            }))
            .catch((error) => {});
    },

    getSimilarity: async (query) => {
        const url = `${apiUrl}/similarity?query=${query}`;
        return httpClient(url, { method: 'get' }, true)
            .then(({ json }) => ({
                data: json.data
            }))
            .catch((error) => {});
    },

    getList: async (resource, params) => {
        try {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const orderSign = order === 'ASC' ? '+' : '-';
            const filter = { ...params.filter };
            if (Object.keys(filter).length > 0) {
                const renameFilter = (key, newKey) => {
                    if (filter[key]) {
                        filter[newKey] = filter[key];
                        delete filter[key];
                    }
                };
                renameFilter('created_at_gte', 'created_at.gte');
                renameFilter('created_at_lte', 'created_at.lte');
                renameFilter('score_gte', 'score.gte');
                renameFilter('score_lte', 'score.lte');
            }
            const query = {
                ...filter,
                sort: orderSign + field,
                page: page,
                per_page: perPage,
                next_page_token: params.meta?.pageTokens?.current?.[page]
            };
            const url = (resource_) =>
                `${apiUrl}/${resource_}?${queryString.stringify(query)}`;
            const hasCursorPagination =
                'cursor_pagination' in filter || 'countless' in filter;
            switch (resource) {
                case 'messages':
                case 'comments': {
                    const { json } = await httpClient(url('messages'), {
                        method: 'get'
                    });
                    const data = json.data.map((resource) => {
                        const group_name =
                            resource.group.name || resource.group.title;
                        return { ...resource, id: resource.id, group_name };
                    });
                    const { pageTokens } = params.meta || {};
                    const nextPageToken = json.next_page_token;
                    if (nextPageToken && pageTokens) {
                        pageTokens.current = {
                            ...pageTokens.current,
                            [page + 1]: nextPageToken
                        };
                    }
                    const pageInfo = {
                        hasPreviousPage: !!pageTokens?.current[page],
                        hasNextPage: data.length === perPage && nextPageToken
                    };
                    return { data, pageInfo };
                }
                case 'votes':
                case 'tags':
                case 'application_members':
                case 'application_invitations':
                case 'debate_suggestions':
                case 'sources_tags':
                case 'proposals':
                case 'translation_entries':
                case 'moderation_entries':
                case 'sub_applications':
                case 'reports': {
                    const { headers, json } = await httpClient(url(resource), {
                        method: 'get'
                    });
                    const data = json.data.map((resource) => ({
                        ...resource,
                        id: resource.id
                    }));
                    const { pageTokens } = params.meta || {};
                    const nextPageToken = json.next_page_token;
                    if (nextPageToken && pageTokens) {
                        pageTokens.current = {
                            ...pageTokens.current,
                            [page + 1]: nextPageToken
                        };
                    }
                    const pageInfo = !hasCursorPagination
                        ? undefined
                        : {
                              hasPreviousPage: !!pageTokens?.current[page],
                              hasNextPage:
                                  data.length === perPage && nextPageToken
                          };
                    const total = hasCursorPagination
                        ? undefined
                        : parseInt(headers.get('total'), 10);
                    return { data, total, pageInfo };
                }
                case 'sources': {
                    const { json } = await httpClient(url(resource), {
                        method: 'get'
                    });
                    const data = json.data.map((resource) => {
                        const id =
                            params.filter['typeID'] === 'id'
                                ? resource.id
                                : resource.uid;
                        return { ...resource, id, identifier: resource.id };
                    });
                    const { pageTokens } = params.meta || {};
                    const nextPageToken = json.next_page_token;
                    if (nextPageToken && pageTokens) {
                        pageTokens.current = {
                            ...pageTokens.current,
                            [page + 1]: nextPageToken
                        };
                    }
                    const pageInfo = {
                        hasPreviousPage: !!pageTokens?.current[page],
                        hasNextPage: data.length === perPage && nextPageToken
                    };
                    return { data, pageInfo };
                }
                case 'users': {
                    const { json } = await httpClient(url(resource), {
                        method: 'get'
                    });
                    const data = json.data.map((resource) => ({
                        ...resource,
                        id: resource.hash_id,
                        identifier: resource.id
                    }));
                    const { pageTokens } = params.meta || {};
                    const nextPageToken = json.next_page_token;
                    if (nextPageToken && pageTokens) {
                        pageTokens.current = {
                            ...pageTokens.current,
                            [page + 1]: nextPageToken
                        };
                    }
                    const pageInfo = {
                        hasPreviousPage: !!pageTokens?.current[page],
                        hasNextPage: data.length === perPage && nextPageToken
                    };
                    return { data, pageInfo };
                }
                default: {
                    const { headers, json } = await httpClient(url(resource), {
                        method: 'get'
                    });
                    const data = json.data.map((resource) => ({
                        ...resource,
                        id: resource.slug,
                        identifier: resource.id
                    }));
                    const { pageTokens } = params.meta || {};
                    const nextPageToken = json.next_page_token;
                    if (nextPageToken && pageTokens) {
                        pageTokens.current = {
                            ...pageTokens.current,
                            [page + 1]: nextPageToken
                        };
                    }
                    const pageInfo = !hasCursorPagination
                        ? undefined
                        : {
                              hasPreviousPage: !!pageTokens?.current[page],
                              hasNextPage:
                                  data.length === perPage && nextPageToken
                          };
                    const total = hasCursorPagination
                        ? undefined
                        : parseInt(headers.get('total'), 10);
                    return { data, total, pageInfo };
                }
            }
        } catch (error) {
            console.log(error);
        }
    },

    getOne: (resource, params) => {
        if (resource === 'settings') {
            const url = `${apiUrl}/${resource}`;
            return httpClient(url, { method: 'get' })
                .then(({ json }) => ({
                    data: { ...json.data.resource, id: params.id }
                }))
                .catch((error) => {});
        } else if (resource === 'groups') {
            const url = `${apiUrl}/${resource}/${params.id}`;
            return httpClient(url, { method: 'get' })
                .then(({ json }) => ({
                    data: {
                        ...json.data.resource,
                        id: json.data.resource.slug,
                        identifier: json.data.resource.id,
                        tag_list: json.data.resource.group_context.tag_list.map(
                            (tag) => tag
                        ),
                        pro_thesis:
                            json.data.resource.group_context.positions[0].name,
                        against_thesis:
                            json.data.resource.group_context.positions[1].name,
                        has_neutral_thesis:
                            json.data.resource.group_context.positions[2] ||
                            false,
                        neutral_thesis:
                            (json.data.resource.group_context.positions[2] &&
                                json.data.resource.group_context.positions[2]
                                    .name) ||
                            null
                    }
                }))
                .catch((error) => {});
        } else if (resource === 'messages' || resource === 'comments') {
            let url = `${apiUrl}/messages/${params.id}`;
            if (params.with_parents) {
                url = url + '?with_parents=true';
            }
            return httpClient(url, { method: 'get' })
                .then(({ json }) => ({
                    data: {
                        ...json.data.resource,
                        sources: json.data.resource.sources,
                        score: json.data.resource.score.toFixed(2),
                        group_name:
                            json.data.resource.group.name ||
                            json.data.resource.group.title
                    }
                }))
                .catch((error) => {});
        } else if (resource === 'debate_suggestions') {
            const url = `${apiUrl}/${resource}/${params.id}`;
            return httpClient(url, { method: 'get' })
                .then(({ json }) => ({
                    data: {
                        ...json.data.resource,
                        id: json.data.resource.slug,
                        identifier: json.data.resource.id,
                        pro_thesis: json.data.resource.position_list
                            .split(',')[0]
                            .replace(/\s+/g, ''),
                        against_thesis: json.data.resource.position_list
                            .split(',')[1]
                            .replace(/\s+/g, ''),
                        has_neutral_thesis:
                            json.data.resource.position_list.split(',').length >
                            2
                    }
                }))
                .catch((error) => {});
        } else if (resource === 'translation_entries') {
            const url = `${apiUrl}/${resource}/${params.id}`;
            return httpClient(url, { method: 'get' })
                .then(({ json }) => ({
                    data: { ...json.data.resource }
                }))
                .catch((error) => {});
        } else if (resource === 'users') {
            const url = `${apiUrl}/${resource}/${params.id}`;
            return httpClient(url, { method: 'get' })
                .then(({ json }) => ({
                    data: {
                        ...json.data.resource,
                        id: json.data.resource.hash_id,
                        identifier: json.data.resource.id
                    }
                }))
                .catch((error) => {});
        } else {
            const url = `${apiUrl}/${resource}/${params.id}`;
            return httpClient(url, { method: 'get' })
                .then(({ json }) => ({
                    data: {
                        ...json.data.resource,
                        id: json.data.resource.slug,
                        identifier: json.data.resource.id
                    }
                }))
                .catch((error) => {});
        }
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url, { method: 'get' })
            .then(({ json }) => ({ data: json.data }))
            .catch((error) => {});
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        let orderSign = order === 'ASC' ? '+' : '-';
        const query = {
            sort: orderSign + field,
            page: page,
            per_page: perPage,
            [params.target]: params.id
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

        if (resource === 'messages' || resource === 'debate_suggestions') {
            return httpClient(url, { method: 'get' })
                .then(({ headers, json }) => ({
                    data: json.data.map((resource) => ({
                        ...resource,
                        id: resource.id
                    })),
                    total: parseInt(headers.get('total'), 10)
                }))
                .catch((error) => {});
        } else {
            return httpClient(url, { method: 'get' })
                .then(({ headers, json }) => ({
                    data: json.data.resource.map((resource) => ({
                        ...resource,
                        id: resource.slug,
                        identifier: resource.id
                    })),
                    total: parseInt(headers.get('total'), 10)
                }))
                .catch((error) => {});
        }
    },

    update: (resource, params) => {
        if (resource === 'settings') {
            if (params.data?.ads?.googleAdManager) {
                if (params.data.ads.googleAdManager.article) {
                    for (let k in params.data.ads.googleAdManager.article) {
                        if (params.data.ads.googleAdManager.article[k] == '') {
                            delete params.data.ads.googleAdManager.article[k];
                        }
                    }
                }
                if (params.data.ads.googleAdManager.thread) {
                    for (let k in params.data.ads.googleAdManager.thread) {
                        if (params.data.ads.googleAdManager.thread[k] == '') {
                            delete params.data.ads.googleAdManager.thread[k];
                        }
                    }
                }
            }
            if (params.data?.text) {
                delete params.data.text;
            }
            if (params.data?.moderation?.blacklist) {
                if (!Array.isArray(params.data.moderation.blacklist)) {
                    params.data.moderation.blacklist =
                        params.data.moderation.blacklist.split(',');
                }
            }
            if (params?.data?.text) {
                params.data.text = JSON.stringify(params.data.text);
            }
            if (params?.data?.provider?.name) {
                params.data.display_name = params.data.provider.name;
            }
            if (params?.data?.provider?.url) {
                params.data.url = params.data.provider.url;
            }
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'patch',
                body: JSON.stringify(params.data)
            })
                .then(({ json }) => ({
                    data: { ...json.data.resource, id: json.data.resource.id }
                }))
                .catch((error) => {});
        } else if (resource === 'users') {
            return httpClient(`${apiUrl}/${resource}/${params.data.slug}`, {
                method: 'patch',
                body: params.data
            })
                .then(({ json }) => ({
                    data: { ...json, id: params.id }
                }))
                .catch((error) => {});
        } else {
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'patch',
                body: params.data
            })
                .then(({ json }) => ({
                    data: { ...json, id: params.id }
                }))
                .catch((error) => {});
        }
    },

    updateMany: (resource, params) => {
        const ids = params.ids.slice(0, params.ids.length - 1);
        ids.forEach((id) => {
            httpClient(`${apiUrl}/${resource}/${id}`, {
                method: 'patch',
                body: JSON.stringify(params.data)
            });
        });
        return httpClient(
            `${apiUrl}/${resource}/${params.ids[params.ids.length - 1]}`,
            {
                method: 'patch',
                body: JSON.stringify(params.data)
            }
        )
            .then(({ json }) => ({
                data: params.ids
            }))
            .catch((error) => {});
    },

    createMany: (resource, params) => {
        const ids = params.ids.slice(0, params.ids.length - 1);
        ids.forEach((id) => {
            params.data['email'] = id;
            httpClient(`${apiUrl}/${resource}`, {
                method: 'post',
                body: JSON.stringify(params.data)
            });
        });
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'post',
            body: JSON.stringify({
                email: params.ids.slice(
                    params.ids.length - 1,
                    params.ids.length
                )[0],
                role: params.data.role
            })
        })
            .then(({ json }) => ({
                data: params.ids
            }))
            .catch((error) => {});
    },

    create: (resource, params) => {
        if (resource === 'groups') {
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'post',
                body: params.data
            })
                .then(({ json }) => ({
                    data: { id: json.data.slug }
                }))
                .catch((error) => {});
        } else if (resource === 'debate_suggestions') {
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'post',
                body: params.data
            })
                .then(({ json }) => ({
                    data: { id: json.data.id }
                }))
                .catch((error) => {});
        } else if (resource === 'consultations') {
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'post',
                body: params.data
            })
                .then(({ json }) => ({
                    data: { id: json.data.resource.slug }
                }))
                .catch((error) => {});
        } else if (resource === 'application_invitations') {
            return httpClient(
                `${apiUrl}/${resource}/${params.invitation_id}/accept`,
                {
                    method: 'post'
                }
            )
                .then(({ json }) => ({
                    data: { id: json.data.resource.id }
                }))
                .catch((error) => {
                    console.log(error);
                });
        } else if (resource === 'exports') {
            const { field, order } = params.sort;
            const orderSign = order === 'ASC' ? '+' : '-';
            const filter = { ...params.filter };
            if (Object.keys(filter).length > 0) {
                const renameFilter = (key, newKey) => {
                    if (filter[key]) {
                        filter[newKey] = filter[key];
                        delete filter[key];
                    }
                };
                renameFilter('created_at_gte', 'created_at.gte');
                renameFilter('created_at_lte', 'created_at.lte');
                renameFilter('score_gte', 'score.gte');
                renameFilter('score_lte', 'score.lte');
            }
            const query = {
                ...filter,
                sort: orderSign + field
            };
            const queryString = encodeURIComponent(JSON.stringify(query));
            return httpClient(
                `${apiUrl}/${resource}?data_name=${params.data_name}&filters=${queryString}`,
                {
                    method: 'post'
                }
            )
                .then(({ json }) => ({
                    data: { id: json.data.resource.id }
                }))
                .catch((error) => {});
        } else {
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'post',
                body: JSON.stringify(params.data)
            })
                .then(({ json }) => ({
                    data: { id: json.data.slug }
                }))
                .catch((error) => {});
        }
    },

    delete: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'delete'
        })
            .then(({ json }) => ({
                data: {}
            }))
            .catch((error) => {
                console.log(error);
            });
    },

    deleteMany: (resource, params) => {
        const ids = params.ids.slice(0, params.ids.length - 1);
        ids.forEach((resourceId) => {
            httpClient(`${apiUrl}/${resource}/${resourceId}`, {
                method: 'delete'
            });
        });
        return httpClient(
            `${apiUrl}/${resource}/${params.ids[params.ids.length - 1]}`,
            {
                method: 'delete'
            }
        )
            .then(({ json }) => ({
                data: params.ids
            }))
            .catch((error) => {});
    }
};
