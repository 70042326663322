import { formatGroupeParlementaire, formatGroupeVotantVote } from "../formatters";
import { VoteIcon } from "@logora/parliament.icons.regular_icons";

const groupesVotantsFields = {
    organeRef: { label: "Groupe politique", field: "organeRef", include: 'organeRef', format: formatGroupeParlementaire },
    pour: { label: "Pour", field: "pour", format: formatGroupeVotantVote },
    contre: { label: 'Contre', field: 'contre', format: formatGroupeVotantVote },
    abstentions: { label: "Abstention", field: "abstentions", format: formatGroupeVotantVote },
    nombreMembresGroupe: { label: "Nombre de membres", field: "nombreMembresGroupe" },
}

const groupesVotantsFilters = [
];

export const groupesVotantsData = {
    key: "groupesVotants",
    name: "Groupes politiques",
    filters: groupesVotantsFilters,
    fields: groupesVotantsFields,
    icon: <VoteIcon width={20} height={20} />,
    rowRedirect: (item) => `/parliament/organes/${item.organeRefUid}`
}