import { useState, useId } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { SearchBar } from './SearchBar';
import { ListNavigation } from './ListNavigation';
import { List } from './List';

export const MultiList = ({ datasets = {}, showSearch = false, showListSearch = false, variant = "button", navigateOnChange = true, height = 'none' }) => {
    const id = useId()
    const { dataset = Object.keys(datasets)[0] } = useParams();
    const [searchParams] = useSearchParams();
    const [activeList, setActiveList] = useState(dataset);
    const [search, setSearch] = useState(searchParams.get("query"));
    const [totals, setTotals] = useState(
        Object.fromEntries(Object.keys(datasets).map(k => [k, 0]))
    );

    const handleSearchChange = (query) => {
        setSearch(query)
    };

    const handleUpdateTotal = (dataset, total) => {
        setTotals(prevValues => {
            return { ...prevValues, [dataset]: total }
        });
    }

    return (
        <>
            {activeList &&
                <Box sx={{ display: 'flex', flexDirection: 'column', height: height }}>
                    <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                        {showSearch && <SearchBar onSearchChange={handleSearchChange} />}
                        <ListNavigation
                            datasets={datasets}
                            activeList={activeList}
                            totals={totals}
                            onNavChange={(list) => setActiveList(list)}
                            navigateOnChange={navigateOnChange}
                            variant={variant}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, overflow: 'auto', marginRight: "10px" }}>
                        {Object.keys(datasets).map((dataset, index) => {
                            return (
                                <List
                                    dataset={datasets[dataset]}
                                    search={search}
                                    display={dataset === activeList}
                                    key={`${dataset}-${id}`}
                                    id={`${dataset}-${id}`}
                                    role="tabpanel"
                                    tabIndex={index}
                                    showSearch={showListSearch}
                                    onUpdateTotal={handleUpdateTotal}
                                />
                            )
                        })}
                    </Box>
                </Box>
            }
        </>
    );
};
