import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { BackLink } from '@logora/debate.action.back_link';
import { Box } from '@mui/material';

export const AmendementShow = () => {
    const { amendementUid } = useParams();
    const [amendement, setAmendement] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        fetchElement();
    }, []);

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`amendements`, {
                id: amendementUid
            });
            const amendement = response.data;
            if (amendement) {
                setAmendement(amendement);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    if (!amendement) return <div>Loading...</div>;

    return (
        <>
            <BackLink text={"Voir tous les amendements"} to={"#/parliament/recherche/amendements"} />
            <Box sx={{ marginTop: "2em" }}>
                {amendement.uid}
            </Box>
        </>
    );
};
