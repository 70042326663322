import { formatDate, formatActeur, formatVotePosition } from "../formatters";
import { VoteIcon } from "@logora/parliament.icons.regular_icons";

const votesFields = {
    dateVote: { label: "Date", field: "dateVote", sort: "dateVote", format: formatDate },
    auteur: { label: 'Auteur', field: 'acteurRef', include: 'acteurRef', format: formatActeur },
    positionVote: { label: "Position", field: "positionVote", format: formatVotePosition },
}

const votesFilters = [
    { field: "positionVote", label: "Position", type: 'select', defaultValue: null, options: [
        { value: null, label: "Tous" },
        { value: "pour", label: "Pour" },
        { value: "contre", label: "Contre" },
        { value: "abstention" , label: "Abstention" },
    ]},
];

export const voteData = {
    key: "votes",
    name: "Votes",
    filters: votesFilters,
    fields: votesFields,
    icon: <VoteIcon width={20} height={20} />,
    rowRedirect: (item) => `/parliament/acteurs/${item.acteurRefUid}`
}