import React from 'react';
import { Show } from '../components/show/Show';
import { Label } from '@logora/parliament.ui.label';
import { Box } from '@logora/parliament.ui.box';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import styles from "./DossierShow.module.scss"

const DossierDetails = ({ resource }) => {
    return (
        <>
            <div className={styles.header}>
                <Label text={resource.typeInitiative} />
            </div>
            <div className={styles.title}>
                {resource.titre}
            </div>
            <div className={styles.presentation}>
                <div className={styles.rapporteur}>
                    <Box title="Rapporteur" className={styles.boxContainer}>
                        <MemberBox firstName={"Aude"} lastName={"Dupont"} />
                    </Box>
                </div>
                <div className={styles.commission}>
                    <Box title="Commission" className={styles.boxContainer}>
                        <div>Commission des lois</div>
                    </Box>
                </div>
                <div className={styles.date}>
                    <Box title="Date de mise à jour" className={styles.boxContainer}>
                        <div>{resource.dateDernierActe}</div>
                    </Box>
                </div>
            </div>
        </>
    );
};

export const DossierShow = () => {
    return (
        <Show 
            resource={"dossiers"} 
            backLink={"#/parliament/recherche/dossiers"} 
            backText={"Voir tous les dossiers"}
        >
            <DossierDetails />
        </Show >
    );
};
