import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, useTranslate, Link } from 'react-admin';
import { Point as PointIcon } from '@logora/debate.icons.regular_icons';
import { flags } from '../../debate/translation_entries/TranslationList';
import { YouTube } from '@mui/icons-material';
import { Facebook } from '@mui/icons-material';
import { useSettingsContext } from '../../utils/SettingsProvider';
import Stack from '@mui/material/Stack';
import FullNameField from '../../inputs/FullNameField';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import PersonIcon from '@mui/icons-material/Person';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import dayjs from '../../utils/dayJsSetup';
import ModerationReport from './ModerationReport';
import classes from './ModerableElement.module.scss';

const socialIcons = {
    youtube: <YouTube sx={{ fill: '#FF0000' }} />,
    facebook: <Facebook sx={{ fill: '#3B5998' }} />
};

const ModerableElement = ({
    parentId = null,
    moderable = null,
    moderableType,
    moderableScore,
    hideInfos = false,
    showStatus = false,
    submitReformulation
}) => {
    const [entry, setEntry] = useState(moderable || null);
    const [showParent, setShowParent] = useState(false);
    const [showReports, setShowReports] = useState(false);
    const [parents, setParents] = useState([]);
    const [isReformulating, setIsReformulating] = useState(false);
    const [showReformulatedContent, setShowReformulatedContent] = useState(false);
    const settingsContext = useSettingsContext();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const allowReformulation = settingsContext?.settings?.translation?.enable

    const showModerableContent = (entry) => {
        if (entry) {
            switch (moderableType) {
                case 'Message':
                    return entry.content;
                case 'Proposal':
                    return entry.content;
                case 'DebateSuggestion':
                    return entry.name;
                case 'User':
                    return entry.description;
                default:
                    return entry.content;
            }
        } else { return ''; }
    };

    const [reformulatedValue, setReformulatedValue] = useState(entry?.reformulated_content || showModerableContent(entry));

    const fetchElement = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(`messages`, {
                id: parentId,
                with_parents: true
            });
            const entry = response.data;
            const parents = response.data.parents;
            if (entry) {
                setEntry(entry);
            }
            if (parents && parents.length > 0) {
                setParents(parents);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider]);

    const updateReformulatedContent = async (value) => {
		if (reformulatedValue != entry.reformulated_content) {
            let updatedTable = moderableType === "Message" ? "messages" : moderableType === "DebateSuggestion" ? "debate_suggestions": ""
			try {
				const response = await dataProvider.update(updatedTable, {
                    id: entry.id,
					data: { 
						reformulated_content: value 
					}
				});
			} catch (error) { console.log(error) }
		}
	}

    const showModerableType = (entry) => {
        switch (moderableType) {
            case 'Message':
                if (entry.is_reply) {
                    return translate('resources.moderation_entries.type.reply');
                } else if (entry.group_type === 'Source') {
                    return translate(
                        'resources.moderation_entries.type.comment'
                    );
                } else {
                    return translate(
                        'resources.moderation_entries.type.message'
                    );
                }
            case 'Proposal':
                return translate('resources.moderation_entries.type.proposal');
            case 'DebateSuggestion':
                return translate(
                    'resources.moderation_entries.type.debate_suggestion'
                );
            case 'User':
                return translate('resources.moderation_entries.type.user');
            default:
                return null;
        }
    };

    const showModerableOrigin = (entry) => {
        switch (moderableType) {
            case 'Message':
                if (entry.group_type === 'Source') {
                    return entry.group.source_url ? (
                        <a
                            className={classes.originLink}
                            href={entry.group?.source_url}
                            target="_blank"
                            rel={'noopener noreferrer'}
                            style={{
                                textDecoration: 'none',
                                color: 'rgb(91, 127, 241)'
                            }}
                        >
                            {entry.group?.title}
                        </a>
                    ) : (
                        entry.group.title
                    );
                } else {
                    return (
                        <a
                            className={classes.originLink}
                            href={entry?.direct_url}
                            target="_blank"
                            rel={'noopener noreferrer'}
                            style={{
                                textDecoration: 'none',
                                color: 'rgb(91, 127, 241)'
                            }}
                        >
                            {entry?.group?.name}
                        </a>
                    );
                }
            case 'Proposal':
                return (
                    <a
                        className={classes.originLink}
                        href={entry?.direct_url}
                        target="_blank"
                        rel={'noopener noreferrer'}
                        style={{
                            textDecoration: 'none',
                            color: 'rgb(91, 127, 241)'
                        }}
                    >
                        {entry.consultation?.title}
                    </a>
                );
            case 'DebateSuggestion':
            default:
                return null;
        }
    };

    const handleReformulate = (value) => {
        setReformulatedValue(value) 
    }

    const handleSubmitReformulation = (value) => {
        if (allowReformulation) {
            updateReformulatedContent(value);
            submitReformulation(value);
            setIsReformulating(false);
        }
    }

    useEffect(() => {
        setShowReformulatedContent(false); // Reset show status
        setReformulatedValue(moderable?.reformulated_content || showModerableContent(moderable)) // Reset reformulated_content default value
        if (parentId && moderable == null) {
            fetchElement();
        } else if (moderable !== null) {
            setEntry(moderable);
        }
    }, [moderable]);


    return (
        entry && (
            <>
                {entry?.moderation_entry?.moderable_type !==
                    'SocialModerator::ModerableContent' &&
                    !hideInfos && (
                        <div className={classes.entryHeader}>
                            <div className={classes.entryType}>
                                {showModerableType(entry)}
                            </div>
                            {showModerableOrigin(entry)}
                        </div>
                    )}
                {parents &&
                    parents.length > 0 &&
                    parents.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).map((elm) => (
                        <ModerableElement
                            key={elm.id}
                            moderable={elm}
                            moderableType={'Message'}
                            hideInfos
                        />
                ))}
                { allowReformulation && entry.reformulated_content && (showModerableContent(entry) != entry.reformulated_content) &&
                    <Box display={"flex"} gap={".5em"} marginTop={"1em"}>
                        <Switch onChange={() => setShowReformulatedContent(!showReformulatedContent)} checked={showReformulatedContent} size="small" label="Contenu original" />
                        <span>Contenu reformulé</span>
                    </Box>
                }
                <Paper className={classes.content} elevation={1}>
                    <div className={classes.contentHeader}>
                        {entry?.moderation_entry?.moderable_type ===
                            'SocialModerator::ModerableContent' ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                                sx={{
                                    textTransform: 'capitalize',
                                    marginBottom: '15px'
                                }}
                            >
                                {socialIcons[entry?.moderable_source.provider]}{' '}
                                Utilisateur {entry?.moderable_source.provider}
                            </Stack>
                        ) : (
                            <Link
                                to={`/users/${moderableType === 'User'
                                        ? entry.hash_id
                                        : entry.author?.hash_id
                                    }`}
                            >
                                <div
                                    className={classes.user}
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        alignItems: 'center',
                                        color: 'rgb(91, 127, 241)',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FullNameField
                                        user={entry.author || entry}
                                    />
                                </div>
                            </Link>
                        )}
                        <>
                            <span className={classes.createdAt}>
                                {dayjs(entry.created_at).format(
                                    'DD-MM-YYYY HH:mm'
                                )}
                            </span>
                            {entry?.position && (
                                <div className={classes.position}>
                                    {entry.position.name}
                                </div>
                            )}
                        </>
                    </div>
                    {moderableType === 'Proposal' && (
                        <b className={classes.moderableTitle}>{entry.title}</b>
                    )}
                    <Box sx={{ position: 'relative' }}>
                        { isReformulating ?
                            <TextField
                                onChange={(e) => handleReformulate(e.target.value)}
                                value={reformulatedValue}
                                variant="outlined"
                                multiline
                                fullWidth
                            />
                        :
                            <div
                                className={classes.moderableContent}
                                dangerouslySetInnerHTML={{
                                    __html: showReformulatedContent ? entry?.reformulated_content && entry?.reformulated_content : showModerableContent(entry)
                                }}
                            ></div>
                        }
                        <Box display={"flex"} gap={"1em"} marginTop={"1em"}>
                        {(moderableType === 'Proposal' ||
                            moderableType === 'Message') && (
                                <a
                                    className={classes.originAbsoluteLink}
                                    href={entry?.direct_url}
                                    target="_blank"
                                    rel={'noopener noreferrer'}
                                >
                                    {translate(
                                        'resources.moderation_entries.label.see_message'
                                    )}
                                </a>
                            )}
                            { allowReformulation && ( moderableType === 'Message' || moderableType === 'DebateSuggestion' ) && 
                                <div className={classes.originAbsoluteLink} onClick={() => setIsReformulating(true)}>
                                    {translate(
                                        'resources.moderation_entries.label.reformulate'
                                    )}
                                </div>
                            }
                            { isReformulating &&
                                <div className={`${classes.entryStatItem} ${classes.reformulateBtn}`} onClick={() => handleSubmitReformulation(reformulatedValue)}>Valider</div>
                            }
                        </Box>
                    </Box>
                    {entry?.sources && (
                        <div className={classes.subtitle}>
                            {entry?.sources?.map((source) => (
                                <a
                                    className={classes.sourcesContainer}
                                    href={source.source_url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {source.title}
                                </a>
                            ))}
                        </div>
                    )}
                    {entry?.upvotes > 0 ? (
                        <>
                            <div className={classes.footer}>
                                <div>
                                    {translate(
                                        'resources.moderation_entries.label.upvotes'
                                    )}
                                </div>
                                <span>{entry.upvotes}</span>
                            </div>
                        </>
                    ) : null}
                    {showStatus && moderableType === 'Message' && (
                        <div className={classes.statusHeader}>
                            <div className={classes.status}>
                                {entry.status === 'rejected'
                                    ? translate(
                                        'resources.messages.status.rejected'
                                    )
                                    : entry.status === 'accepted'
                                        ? translate(
                                            'resources.messages.status.accepted'
                                        )
                                        : translate(
                                            'resources.messages.status.pending'
                                        )}
                            </div>
                        </div>
                    )}
                </Paper>
                {parentId &&
                    entry.translation_entries.length > 0 &&
                    entry.translation_entries.map((e) => (
                        <Paper
                            sx={{ marginLeft: '25px !important' }}
                            className={classes.content}
                            elevation={1}
                        >
                            <span
                                className={classes.translatedTitle}
                            >{`${e.target_language.toUpperCase()} ${flags[e.target_language]
                                }`}</span>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: e.translated_content
                                }}
                            ></div>
                        </Paper>
                    ))}
                {!hideInfos && (
                    <>
                        <div className={classes.entryStats}>
                            {moderableScore > 0 && (
                                <Tooltip
                                    title={translate(
                                        'resources.moderation_entries.label.moderation_score'
                                    )}
                                >
                                    <div className={classes.entryStatItem}>
                                        {Math.floor(100 - moderableScore)} %{' '}
                                        <AvTimerIcon />
                                    </div>
                                </Tooltip>
                            )}
                            {entry.author?.moderation_score > 0 && (
                                <Tooltip
                                    title={translate(
                                        'resources.moderation_entries.label.user_score'
                                    )}
                                >
                                    <div className={classes.entryStatItem}>
                                        {entry.author?.moderation_score} %{' '}
                                        <PersonIcon />
                                    </div>
                                </Tooltip>
                            )}
                            {entry.score > 0 && (
                                <Tooltip
                                    title={translate(
                                        'resources.moderation_entries.label.relevance_score'
                                    )}
                                >
                                    <div className={classes.entryStatItem}>
                                        {Math.floor(entry.score)}
                                        <PointIcon
                                            className={classes.pointIcon}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {entry.reports?.length > 0 && (
                                <>
                                    <Tooltip
                                        title={translate(
                                            'resources.moderation_entries.label.reports',
                                            { count: entry.reports.length }
                                        )}
                                    >
                                        <div
                                            onClick={() => setShowReports(true)}
                                            className={classes.reportItem}
                                        >
                                            {translate(
                                                'resources.moderation_entries.label.show_reports',
                                                {
                                                    smart_count:
                                                        entry.reports.length
                                                }
                                            )}{' '}
                                            <ErrorOutlineIcon />
                                        </div>
                                    </Tooltip>
                                    <Drawer
                                        anchor={'right'}
                                        open={showReports}
                                        onClose={() => setShowReports(false)}
                                    >
                                        <div className={classes.drawerTitle}>
                                            {translate(
                                                'resources.reports.name',
                                                { smart_count: 2 }
                                            )}
                                        </div>
                                        {entry.reports.map((report) => (
                                            <Box
                                                sx={{
                                                    width: '500px',
                                                    padding: '15px'
                                                }}
                                            >
                                                <ModerationReport
                                                    report={report}
                                                />
                                            </Box>
                                        ))}
                                    </Drawer>
                                </>
                            )}
                            { entry?.reply_to_id && (
                                <React.Fragment key={'left'}>
                                    <Tooltip
                                        title={translate(
                                            'resources.reports.labels.see_message'
                                        )}
                                    >
                                        <div
                                            onClick={() => setShowParent(true)}
                                            className={classes.showParent}
                                        >
                                            {translate(
                                                'resources.moderation_entries.label.parent'
                                            )}
                                        </div>
                                    </Tooltip>
                                    <Drawer
                                        anchor={'right'}
                                        open={showParent}
                                        onClose={() => setShowParent(false)}
                                    >
                                        <div className={classes.drawerTitle}>
                                            {translate(
                                                'resources.moderation_entries.label.parent_argument'
                                            )}
                                        </div>
                                        <Box
                                            sx={{
                                                width: '500px',
                                                padding: '15px'
                                            }}
                                        >
                                            <ModerableElement
                                                parentId={entry.reply_to_id}
                                                moderableType={entry?.moderable_type}
                                                hideInfos
                                            />
                                        </Box>
                                    </Drawer>
                                </React.Fragment>
                            )}
                        </div>
                    </>
                )}
            </>
        )
    );
};

export default ModerableElement;
