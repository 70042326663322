import React from 'react';
import { useDataProvider } from 'react-admin';
import { Box } from '@mui/material';
import { StatBox } from '@logora/parliament.ui.stat_box';
import {
    QuestionIcon,
    SpeechIcon,
    DocumentIcon
} from '@logora/parliament.icons.regular_icons';
import useFetchStats from '../stats/useFetchStats';

export const ActeurDashboard = ({ acteur }) => {
    const dataProvider = useDataProvider();
    const {
        stats,
        loading: statsLoading,
        error: statsError
    } = useFetchStats(dataProvider);
    if (!statsLoading && !statsError) {
        return (
            <Box
                display="flex"
                flexDirection="row"
                marginTop="2em"
                gap="2em"
                width="100%"
            >
                <StatBox
                    icon={<DocumentIcon />}
                    mainStat={acteur.nombreAmendements}
                    text="amendements déposés"
                    average={stats.amendements.moyenne}
                    maxValue={stats.amendements.ecartMoyenneMax}
                />
                <StatBox
                    icon={<SpeechIcon />}
                    mainStat={acteur.nombreInterventions}
                    text="interventions en hémicycle"
                    average={stats.interventions.moyenne}
                    maxValue={stats.interventions.ecartMoyenneMax}
                />
                <StatBox
                    icon={<QuestionIcon />}
                    mainStat={acteur.nombreQuestions}
                    text="questions posées"
                    average={stats.questions.moyenne}
                    maxValue={stats.questions.ecartMoyenneMax}
                />
            </Box>
        );
    }
};
