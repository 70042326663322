import { formatDate, formatNumber, formatNumero, formatTexteLegislatif, formatActeur } from "../formatters";
import { AmendmentIcon } from "@logora/parliament.icons.regular_icons";

const amendementsFields = {
    'datePublication': { label: "Date de dépôt", field: "datePublication", format: formatDate, sort: "datePublication" },
    'acteurRef': { label: "Auteur", field: "acteurRef", include: "acteurRef", format: formatActeur },
    'numeroLong': { label: "Numéro", field: "numeroLong", format: formatNumero },
    'texteLegislatifRef': { label: "Nom du texte", field: "texteLegislatifRef", include: "texteLegislatifRef", format: formatTexteLegislatif },
    'divisionTitre': { label: "Article", field: "divisionTitre" },
    'nombreCoSignataires': { label: "Nombre signataires", field: "nombreCoSignataires", format: formatNumber },
    'sortAmendement': { label: "Sort", field: "sortAmendement" }
}

const amendementsFilters = [
    { field: "uid", label: 'UID', type: 'text' },
];

export const amendementData = {
    key: "amendements",
    name: "Amendements",
    filters: amendementsFilters,
    fields: amendementsFields,
    icon: <AmendmentIcon height={20} width={20} />
}