import React, { useState, useEffect, useCallback } from 'react';
import { Box, Tab } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { useDataProvider } from 'react-admin';
import { Show } from '../components/show/Show';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { Label } from '@logora/parliament.ui.label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { AssembleeHemicycle } from '@logora/parliament.parliament_chamber.assemble_hemicycle';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { MultiList } from '../search/MultiList';
import { mandatData } from '../mandats';
import { questionData } from '../questions';
import { amendementData } from '../amendements';
import { interventionData } from '../interventions';
import dayjs from '../../utils/dayJsSetup';
import styles from "./OrganeShow.module.scss"
import cloneDeep from "lodash/cloneDeep"

const commonMandatParams = ['acteurRef'];
const includeMandatParams = {
    "Circonscription": [...commonMandatParams],
    "Assemblée": [...commonMandatParams, 'acteurRef.groupeParlementaire'],
    "Sénat": [...commonMandatParams],
    "Groupe politique": [...commonMandatParams, 'acteurRef.groupeParlementaire'],
    "Commission permanente": [...commonMandatParams]
}

const OrganeDetails = ({ resource, resourceId }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [membres, setMembres] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (resource.type != "Circonscription") {
            fetchMembres()
        }
        if (resource.type === "Circonscription") {
            setMembres(resource.parlementairesCirconscription)
        }
    }, []);

    const mandatsFilters = [
        { field: "organeRefUid", hidden: true, defaultValue: resource.uid, type: 'text' },
        { field: "actif", hidden: true, defaultValue: true, label: "Actif", type: 'text' }
    ];

    const compositionDataset = {
        'mandats': Object.assign(mandatData, { filters: mandatsFilters })
    }

    const getDatasets = () => {
        const questionsFilters = questionData.filters
        const questionsDataset = Object.assign(cloneDeep(questionData), { filters: questionsFilters })

        const interventionsFilters = interventionData.filters
        const interventionsDataset = Object.assign(cloneDeep(interventionData), { filters: interventionsFilters })

        const amendementsFilters = amendementData.filters
        const amendementsDataset = Object.assign(cloneDeep(amendementData), { filters: amendementsFilters })

        return {
            'amendements': amendementsDataset,
            'questions': questionsDataset,
            'interventions': interventionsDataset
        }
    }

    const fetchMembres = useCallback(async () => {
        console.log(resource.type)
        try {
            const response = await dataProvider.getList("mandats", {
                filter: { organeRefUid: resourceId },
                include: includeMandatParams[resource.type],
                pagination: { perPage: 1000 }
            });
            const membres = response.data;
            if (membres) {
                setMembres(membres);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider])

    const president = membres?.find(m => m.codeQualite?.toLowerCase().includes("président"))

    const attributeMemberToHemicyle = (membre) => {
        console.log(membre)
        let formattedMember = {
            placeHemicycle: parseInt(membre?.acteurRef?.placeHemicycle || membre?.placeHemicycle),
            color: membre?.acteurRef?.groupeParlementaire?.couleurAssociee || membre?.groupeParlementaire?.couleurAssociee || "#777",
            prenom: membre?.acteurRef?.prenom || membre?.prenom,
            nom: membre?.acteurRef?.nom || membre?.nom,
            urlImage: membre?.acteurRef?.urlImage || membre?.urlImage,
            parliamentGroup: {
                color: membre?.acteurRef?.groupeParlementaire?.couleurAssociee || membre?.groupeParlementaire?.couleurAssociee,
                groupName: membre?.acteurRef?.groupeParlementaire?.libelle || membre?.groupeParlementaire?.libelle,
            }
        }
        return {
            id: formattedMember.placeHemicycle,
            color: formattedMember.color,
            labelBackgroundColor: "#FFF",
            label:
                <Box padding={"0.5em"} color="#434343">
                    <MemberBox
                        firstName={formattedMember.prenom}
                        lastName={formattedMember.nom}
                        imageUrl={formattedMember.urlImage}
                        parliamentGroup={formattedMember.parliamentGroup}
                    />
                </Box>,
            position: 'top'
        }
    }

    return (
        <>
            <Box display={"flex"} flexDirection={"column"}>
                <Box
                    className={styles.avatarBackground}
                    display={'flex'}
                    gap={'2em'}
                    sx={{ marginTop: '1em' }}
                >
                    <div className={styles.imageWrapper}>
                        <img src="https://placehold.co/124x124"></img>
                    </div>
                    <Box display="flex" flexDirection={"column"} marginBottom={"1em"}>
                        <div className={styles.header}>
                            {resource.chambre && <ChamberLabel chamber={resource.chambre} />}
                            <Label text={resource.type} />
                        </div>
                        <div className={styles.title}>
                            {resource.libelle}
                        </div>
                        <div className={styles.subtitle}>
                            {`Créé en ${dayjs(resource.dateDebut).format("MMMM YYYY")} et ${resource.actif ? "toujours actif" : "inactif aujourd'hui"}`}
                        </div>
                    </Box>
                </Box>
                <div className={styles.presentation}>
                    <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        gap="0.5em"
                        sx={{ backgroundColor: '#f9f9f9', padding: '15px', width: '100%' }}
                    >
                        <Box display="flex" flexDirection="column" gap="1em">
                            {resource.positionPolitique &&
                                <Box display="flex" flexDirection="column">
                                    <UiBox
                                        className={styles.uiBox}
                                        title="Position"
                                    >
                                        {resource.positionPolitique}
                                    </UiBox>
                                </Box>
                            }
                            {resource.nombreMembres > 0 &&
                                <Box display="flex" flexDirection="column">
                                    <UiBox
                                        className={styles.uiBox}
                                        title="Effectif"
                                    >
                                        <>{resource.nombreMembres} membres</>
                                    </UiBox>
                                </Box>
                            }
                            {resource.organeParentRef &&
                                <Box display="flex" flexDirection="column">
                                    <UiBox
                                        className={styles.uiBox}
                                        title="Appartenance"
                                    >
                                        {resource.organeParentRef.libelle}
                                    </UiBox>
                                </Box>
                            }
                            {president && president.acteurRef &&
                                <Box display="flex" flexDirection="column">
                                    <UiBox
                                        className={styles.uiBox}
                                        title="Président"
                                    >
                                        <MemberBox
                                            firstName={president?.acteurRef.prenom}
                                            lastName={president?.acteurRef.nom}
                                            imageUrl={president?.acteurRef.urlImage}
                                        />
                                    </UiBox>
                                </Box>
                            }
                            {resource.parlementairesCirconscription && resource.parlementairesCirconscription.length > 0 && 
                                <Box display="flex" flexDirection="column">
                                    <UiBox
                                        className={styles.uiBox}
                                        title="Parlementaire actuel"
                                    >
                                        <MemberBox
                                            firstName={resource.parlementairesCirconscription[0].prenom}
                                            lastName={resource.parlementairesCirconscription[0].nom}
                                            imageUrl={resource.parlementairesCirconscription[0].urlImage}
                                        />
                                    </UiBox>
                                </Box>
                            }
                        </Box>
                        <Box flex={2} className={styles.svgContainer}>
                            <AssembleeHemicycle
                                className={styles.svgIcon}
                                highlightedSeats={
                                    membres?.map(attributeMemberToHemicyle)
                                }
                            />
                        </Box>
                    </Box>
                </div>
            </Box>
            <div className={styles.corps}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={currentTab}
                            onChange={(e, tab) => setCurrentTab(tab)}
                            sx={{
                                [`& .${tabsClasses.indicator}`]: {
                                    backgroundColor: '#434343 !important'
                                }
                            }}
                        >
                            <Tab className={styles.tab} label="Travaux" />
                            <Tab className={styles.tab} label="Composition" />
                        </Tabs>
                    </Box>
                    {currentTab === 0 && (
                        <div role="tabpanel" value={0} index={0}>
                            <MultiList
                                datasets={getDatasets()}
                                showListSearch={true}
                                navigateOnChange={false}
                            />
                        </div>
                    )}
                    {currentTab === 1 && (
                        <div role="tabpanel" value={1} index={1}>
                            <MultiList
                                datasets={compositionDataset} 
                                showListSearch={true} 
                                navigateOnChange={false} 
                            />
                        </div>
                    )}
                </Box>
            </div>
        </>
    );
};

export const OrganeShow = () => {
    return (
        <Show
            resource={"organes"}
            includes={['organeParentRef', 'parlementairesCirconscription']}
            backLink={"#/parliament/recherche/organes"}
            backText={"Voir tous les organes"}
        >
            <OrganeDetails />
        </Show >
    );
};
