import React from 'react';
import { Show } from '../components/show';
import { Box } from '@mui/material';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { AssembleeHemicycle } from '@logora/parliament.parliament_chamber.assemble_hemicycle';
import { Label } from '@logora/parliament.ui.label';
import { MultiList } from '../search/MultiList';
import { useDataProvider } from 'react-admin';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { groupesVotantsData, voteData } from '../votes';
import styles from './ScrutinShow.module.scss';
import dayjs from '../../utils/dayJsSetup';
import useFetchScrutinVotes from './useFetchScrutinVotes';
import cloneDeep from 'lodash/cloneDeep'

const ScrutinDetails = ({ resource }) => {
    const dataProvider = useDataProvider();
    const { votes, loading: votesLoading, error: votesError } = useFetchScrutinVotes(dataProvider, resource.uid);

    const getCodeClassName = (code) => {
        switch (code) {
            case "rejeté":
                return "rejected";
            case "adopté":
                return "adopted";
            default:
                return "undefined";
        }
    }

    const attributeVoteToHemicyle = (vote) => {
        if (vote.votePosition === "nonVotants") { return; }
        return {
            id: parseInt(vote?.acteurRef?.mandatPrincipal?.placeHemicycle),
            color: vote.positionVote === "pour" ? '#2E7D32' : vote.positionVote === "contre" ? '#D32F2F' : vote.positionVote === "abstention" ? "#455A64" : "#CFD8DC",
            label:
                <Box padding={"0.5em"}>
                    <MemberBox
                        size="small"
                        firstName={vote?.acteurRef?.prenom}
                        lastName={vote?.acteurRef?.nom}
                        imageUrl={vote?.acteurRef?.urlImage}
                    />
                </Box>,
            position: 'top'
        }
    }

    const getDatasets = () => {
        const votesFilters = voteData.filters.concat([
            { field: "scrutinRefUid", hidden: true, defaultValue: resource.uid, type: 'text', defaultSort: true },
        ])
        const votesDataset = Object.assign(cloneDeep(voteData), { filters: votesFilters })

        const groupesVotantsFilters = groupesVotantsData.filters.concat([
            { field: "scrutinRefUid", hidden: true, defaultValue: resource.uid, type: 'text', defaultSort: true },
        ])
        const groupesVotantsDataset = Object.assign(cloneDeep(groupesVotantsData), { filters: groupesVotantsFilters })

        return {
            'groupesVotants': groupesVotantsDataset,
            'votes': votesDataset,
        }
    }

    return (
        <>
            <div className={styles.header}>
                <ChamberLabel chamber={resource.chambre} />
                <Label text={"Scrutin"} />
            </div>
            <div className={styles.scrutinContainer}>
                <p className={styles.titleBox}>{`${resource.libelleTypeVote.charAt(0).toUpperCase() + resource.libelleTypeVote.slice(1)} n° ${resource.numero} sur ${resource.titre}`}</p>
            </div>
            <div className={styles.scrutinContent}>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap="1em">
                    <Box display="flex" flexDirection={"column"}>
                        <Box display="flex" flexDirection="row" gap="1em">
                            <UiBox className={styles.uiBox} title="Date du vote">
                                {dayjs(resource.dateScrutin).format("DD MMMM YYYY")}
                            </UiBox>
                            <UiBox className={styles.uiBox} title="Résultat">
                                <Label className={styles[`${getCodeClassName(resource.code)}`]} text={resource.code} />
                            </UiBox>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="1em" marginTop="1em">
                            <UiBox className={styles.uiBox} title="Objet du scrutin">
                                {resource.objet}
                            </UiBox>
                            <UiBox className={styles.uiBox} title="Synthèse">
                                <Box display="flex" flexDirection="column">
                                    <span>Nombre de votants : {resource.nombreVotants}</span>
                                    <span>Nombre de suffrages exprimés : {resource.suffragesExprimes}</span>
                                    <span>Nombre de suffrages requis pour adoption : {resource.nbrSuffragesRequis}</span>
                                </Box>
                            </UiBox>
                        </Box>
                    </Box>
                    <UiBox className={styles.uiBox} title="Votes">
                        <AssembleeHemicycle
                            className={styles.svgIcon}
                            highlightedSeats={votes.map(attributeVoteToHemicyle)}
                            legend={
                                [
                                    { label: "Pour", color: "#2E7D32" },
                                    { label: "Contre", color: "#D32F2F" },
                                    { label: "Abstention", color: "#455A64" }
                                ]
                            }
                        />
                    </UiBox>
                </Box>
            </div>
            <div className={styles.scrutinLists}>
                <MultiList
                    datasets={getDatasets()}
                    showListSearch={true}
                    navigateOnChange={false}
                    variant={"tab"}
                />
            </div>
        </>
    );
};

export const ScrutinShow = () => {
    return (
        <Show
            resource={'scrutins'}
            includes={["groupesVotants"]}
            backLink={'#/parliament/recherche/scrutins'}
            backText={'Voir tous les scrutins'}
        >
            <ScrutinDetails />
        </Show>
    );
};
