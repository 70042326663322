import React, { useEffect, useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { InterventionBox } from '@logora/parliament.debate.intervention_box';
import styles from "./DebatShow.module.scss"

export const DebatDetails = ({ resource }) => {
    const [interventions, setInterventions] = useState([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (resource) {
            fetchInterventions()
        }
    }, [resource]);

    const fetchInterventions = useCallback(async () => {
        try {
            const response = await dataProvider.getList("interventions", {
                filter: { debatRefUid: resource.uid },
                include: ['acteurRef'],
                sort: { field: "ordreAbsoluSeance", order: "asc" },
                pagination: { perPage: 1000 }
            });
            const interventions = response.data;
            if (interventions) {
                setInterventions(interventions);
            }
        } catch (error) {
            console.log(error);
        }
    }, [dataProvider])

    const displayIntervention = (intervention) => {
        if (intervention.texte) {
            if (intervention.codeGrammaire === "TITRE_TEXTE_DISCUSSION" || intervention.codeGrammaire.includes("PRESENTATION_")) {
                return (
                    <div className={styles.interventionTitle}>{intervention.texte}</div>
                )
            } else {
                return (
                    <>
                        <InterventionBox key={intervention.ordreAbsoluSeance} intervention={intervention} />
                    </>
                )
            }
        } else {
            return null
        }
    }

    return (
        <>
            {interventions?.length > 0 ?
                <div className={styles.corps}>
                    {interventions.map(displayIntervention)}
                </div>
                :
                <div>Aucune intervention dans le débat</div>
            }
        </>
    );
};
