import React from 'react';
import {
    TextField, Checkbox, FormControlLabel, Box, MenuItem
} from '@mui/material';

export const FilterBar = ({ filters, onFilterChange }) => {
    const handleFilterChange = (filter, newValue) => {
        if (filter.format) {
            onFilterChange(filter, filter.format(newValue));
        } else {
            onFilterChange(filter, newValue);
        }
    };

    const renderFilterField = (filter) => {
        switch (filter.type) {
            case 'date':
                return (
                    <TextField
                        label={filter.label}
                        type="date"
                        value={filter.value}
                        defaultValue={filter.defaultValue}
                        onChange={(e) => handleFilterChange(filter, e.target.value)}
                        size='small'
                    />
                );
            case 'boolean':
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={filter.defaultValue}
                                onChange={(e) => handleFilterChange(filter, e.target.checked)}
                            />
                        }
                        label={filter.label}
                    />
                );
            case 'select':
                return (
                    <TextField
                        label={filter.label}
                        value={filter.value}
                        defaultValue={filter.defaultValue}
                        select
                        SelectProps={{ autoWidth: true }}
                        onChange={(e) => handleFilterChange(filter, e.target.value)}
                        size='small'
                        sx={{ minWidth: "10em" }}
                    >
                        {filter.options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            case 'text':
                return (
                    <TextField
                        label={filter.label}
                        value={filter.value}
                        defaultValue={filter.defaultValue}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleFilterChange(filter, e.target.value === "" ? null : e.target.value)
                            }
                        }}
                        size='small'

                    />
                );
        }
    };

    const visibleFilters = filters?.filter(f => f.hidden !== true)

    return (
        <>
            {visibleFilters?.length > 0 &&
                <Box sx={{ display: "flex", flexDirection: "column", margin: "1em 0em" }}>
                    <Box>Filtres</Box>
                    <Box sx={{ display: "flex", flexFlow: "row wrap", gap: "1em", marginTop: "0.5em" }}>
                        {visibleFilters.map((filter) => {
                            return (
                                <Box key={`${filter.field}`} display={"flex"}>
                                    {renderFilterField(filter)}
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            }
        </>
    );
};
