import React, { useState, useEffect } from 'react';
import {
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TableSortLabel,
    TablePagination,
    Paper
} from '@mui/material';
import { useTranslate, useRedirect, useDataProvider } from 'react-admin';
import { FilterBar } from './FilterBar';
import { SearchBar } from './SearchBar';
import { visuallyHidden } from '@mui/utils';
import styles from './List.module.scss'

export const List = ({ dataset, search, display = false, showSearch = false, onUpdateTotal }) => {
    const translate = useTranslate()
    const redirectTo = useRedirect()
    const dataProvider = useDataProvider()
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState(search)
    const [total, setTotal] = useState(0);

    function findFirstObjectWithSortKey(obj) {
        const entry = Object.entries(obj).find(([_, value]) => value?.defaultSort !== undefined)
        return entry ? entry[0] : Object.keys(obj)[0] || {}
    }

    const getDefaultSort = () => {
        if (dataset.fields === undefined) return {}
        const fields = dataset.fields
        const firstFieldKey = findFirstObjectWithSortKey(fields)
        return { field: fields[firstFieldKey]["sort"] || undefined, order: 'desc', name: firstFieldKey }
    }

    const getDefaultFilters = () => {
        if (dataset) {
            const initFilters = dataset.filters || []
            initFilters.map(f => {
                f.value = f.defaultValue || null
            })
            return initFilters
        }
    }

    const [sort, setSort] = useState(getDefaultSort())
    const [filters, setFilters] = useState(getDefaultFilters())
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])

    const handleFilterChange = (filter, newValue) => {
        setFilters((prevFilters) =>
            prevFilters.map((f) =>
                filter.field === f.field ? { ...f, value: newValue } : f
            )
        );
    };

    const handleRowClick = (item) => {
        if (dataset.rowRedirect) {
            redirectTo(dataset.rowRedirect(item))
        } else {
            redirectTo(`/parliament/${dataset.key}/${item.uid || item.id}`)
        }
    };

    const handleSort = (key) => {
        const sortField = dataset.fields[key].sort || dataset.fields[key].field;

        setSort((prevSort) => {
            const order = prevSort.name === key && prevSort.order === "desc" ? "asc" : "desc";
            return { field: sortField, order: order, name: key }
        });
    };

    const fetchData = () => {
        setLoading(true)
        dataProvider.getList(dataset.key, {
            filter: {
                ...filters?.reduce((acc, filter) => {
                    if (filter.value !== null) {
                        acc[filter.field] = filter.value;
                    }
                    return acc;
                }, {})
            },
            pagination: { page: page, perPage: 10 },
            include: Object.values(dataset.fields || {}).reduce((acc, current) => {
                if (current.hasOwnProperty('include')) {
                    acc.push(current.include);
                }
                return acc;
            }, []),
            sort: sort,
            search: searchQuery,
            ...(dataset.distinct && { distinct: dataset.distinct }),
        })
            .then((res) => {
                if (res) {
                    setData(res.data);
                    setTotal(res.total)
                    if (onUpdateTotal) {
                        onUpdateTotal(dataset.key, res.total)
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(`Failed to fetch ${dataset.key} data:`, error);
                setLoading(false);
            });
    };

    const fetchTotal = () => {
        dataProvider.getList(dataset.key, {
            sort: {},
            pagination: { page: 1, perPage: 1 },
            filter: {
                ...filters?.reduce((acc, filter) => {
                    if (filter.value !== null) {
                        acc[filter.field] = filter.value;
                    }
                    return acc;
                }, {})
            },
            search: search
        })
            .then((res) => {
                if (res.total) {
                    setTotal(res.total)
                    if (onUpdateTotal) {
                        onUpdateTotal(dataset.key, res.total)
                    }
                }
            })
            .catch((error) => console.error(`Failed to fetch ${dataset.key} total:`, error));
    };

    useEffect(() => {
        setSearchQuery(search)
    }, [search])

    useEffect(() => {
        if (display) {
            setPage(1)
            setData([])
            fetchData()
        }
    }, [searchQuery, filters])

    useEffect(() => {
        if (display) {
            fetchData()
        }
    }, [sort, page, display]);

    useEffect(() => {
        if (!display) {
            fetchTotal()
        }
    }, [searchQuery, display])

    if (!display) return null

    return (
        <div className={styles.container}>
            <div>
                <Box display="flex" flexDirection={"row"}>
                    <FilterBar
                        filters={filters}
                        onFilterChange={handleFilterChange}
                    />
                    {showSearch &&
                        <SearchBar
                            onSearchChange={(query) => setSearchQuery(query)}
                            styles={{ width: "max-content !important", margin: "1em 0em 1em auto", alignSelf: "flex-end" }}
                            size="small"
                        />
                    }
                </Box>
            </div>
            <TableContainer component={Paper} sx={{ width: "100%", minHeight: "40em" }}>
                <Table>
                    {loading ?
                        <div className={styles.loader}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            <TableHead>
                                <TableRow className={styles.tableHeader}>
                                    {Object.keys(dataset.fields).map((key) => (
                                        <TableCell
                                            key={dataset.fields[key].field}
                                            className={styles.tableHeaderCell}
                                            sortDirection={sort.name === key ? sort.order : false}
                                        >
                                            <TableSortLabel
                                                active={sort.name === key}
                                                direction={sort.order}
                                                onClick={() => handleSort(key)}
                                            >
                                                {dataset.fields[key].label}
                                                {sort.name === key ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {sort.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className={styles.tableBody}>
                                {data.map((item, rowIndex) => (
                                    <TableRow
                                        key={rowIndex}
                                        className={styles.hoverRow}
                                        onClick={() => handleRowClick(item)}
                                    >
                                        {Object.keys(dataset.fields).map((key) => (
                                            <TableCell key={dataset.fields[key].field} className={styles.tableCell}>
                                                {dataset.fields[key].format ?
                                                    dataset.fields[key].format(dataset.fields[key].field, item)
                                                    :
                                                    item[dataset.fields[key].field]?.toString() || '-'
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow align='left' className={styles.filterRow}>
                                    <TablePagination
                                        page={page - 1}
                                        count={total}
                                        labelRowsPerPage={translate("pos.main.rows_per_page")}
                                        rowsPerPage={10}
                                        rowsPerPageOptions={[]}
                                        onPageChange={(event, newPage) => { setPage(newPage + 1) }}
                                        labelDisplayedRows={({ from, to, count }) => {
                                            return `${from}-${to} ${translate("pos.main.from_to_pagination")} ${count}`
                                        }}
                                    />
                                </TableRow>
                            </TableFooter>
                        </>
                    }
                </Table>
            </TableContainer>
        </div>
    );
};