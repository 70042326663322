import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';
import React from 'react';
import { Integration } from './integration';
import { IntegrationStatusChip } from './IntegrationStatusChip';
import { IntegrationActions } from './IntegrationActions';
import { useQuery } from '@tanstack/react-query';
import httpClient from '../../httpClient';
import { Link, useTranslate } from 'react-admin';

interface IntegrationCardProps {
    provider: string;
    icon: React.ReactNode;
    authUrl: string;
    redirectUri: string;
    clientId: string;
    scope: string;
    state?: string;
    accessType?: string;
    forceAuth?: boolean;
}

export const IntegrationCard = ({
    provider,
    icon,
    authUrl,
    redirectUri,
    clientId,
    state,
    scope,
    accessType,
    forceAuth
}: IntegrationCardProps) => {
    const { data: integration, isLoading: isIntegrationLoading } = useQuery<
        Integration | { status: 'not_connected' }
    >({
        queryKey: ['integration', provider],
        queryFn: async () => {
            const response = await httpClient(
                `${process.env.REACT_APP_SOCIAL_MODERATOR_URL}/integrations/${provider}`
            );

            if (!response) {
                return { status: 'not_connected' };
            }

            return response.json;
        },
        staleTime: 60 * 1000
    });

    const translate = useTranslate();

    return (
        <Card
            variant="outlined"
            sx={{
                minWidth: 250,
                borderRadius: 3,
                borderColor: '#E8E8E8'
            }}
        >
            <CardHeader
                title={
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={3}
                    >
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Box
                                width={32}
                                height={32}
                                borderRadius="50%"
                                sx={{
                                    backgroundColor: '#F5F1EF',
                                    display: 'grid',
                                    placeItems: 'center'
                                }}
                            >
                                {icon}
                            </Box>

                            <Typography
                                fontWeight={600}
                                fontSize={12}
                                textTransform="capitalize"
                            >
                                {provider}
                            </Typography>
                        </Stack>

                        {isIntegrationLoading ? (
                            <Skeleton
                                variant="rounded"
                                width={110}
                                height={20}
                                sx={{
                                    margin: 0,
                                    padding: 0,
                                    borderRadius: 100
                                }}
                            />
                        ) : (
                            <IntegrationStatusChip
                                status={integration?.status || 'not_connected'}
                            />
                        )}
                    </Stack>
                }
            />

            <Stack sx={{ paddingInline: 2 }}>
                {isIntegrationLoading ? (
                    <>
                        <Skeleton
                            sx={{
                                transform: 'none',
                                height: 38,
                                borderRadius: 3
                            }}
                        />

                        <Skeleton
                            sx={{
                                transform: 'none',
                                height: 15,
                                borderRadius: 2,
                                marginBlock: 1
                            }}
                        />
                    </>
                ) : (
                    <>
                        <IntegrationActions
                            integration={integration!}
                            authUrl={authUrl}
                            provider={provider}
                            redirectUri={redirectUri}
                            clientId={clientId}
                            state={state}
                            scope={scope}
                            accessType={accessType}
                            forceAuth={forceAuth}
                        />

                        {integration?.status == 'active' && (
                            <Stack
                                direction="row"
                                fontSize={11}
                                color="gray.400"
                                gap={0.5}
                                marginBottom={0.75}
                            >
                                {translate(
                                    'resources.integrations.fields.moderable_sources'
                                )}
                                :
                                <Typography fontSize={11} fontWeight={600}>
                                    {integration.moderable_sources.map(
                                        ({ name, url }, i) => (
                                            <span key={name}>
                                                <Link to={url}>{name}</Link>
                                                {i <
                                                    integration
                                                        .moderable_sources
                                                        .length -
                                                        1 && ', '}
                                            </span>
                                        )
                                    )}
                                </Typography>
                            </Stack>
                        )}
                    </>
                )}
            </Stack>
        </Card>
    );
};
