import { formatDate, formatCapitalize, formatActeur, formatLabel, formatNumero } from '../formatters'
import { DocumentIcon } from '@logora/parliament.icons.regular_icons'

const documentsFields = {
    dateCreation: { label: 'Date', field: 'dateCreation', format: formatDate, sort: "dateCreation", defaultSort: true },
    typeLibelle: { label: 'Type', field: 'typeLibelle', format: formatLabel },
    auteurPrincipal: { label: 'Auteur', field: 'auteurPrincipal', include: 'auteurPrincipal', format: formatActeur },
    numero: { label: 'Numéro', field: 'numNotice', format: formatNumero },
    titre: { label: 'Titre', field: 'titrePrincipalCourt', format: formatCapitalize },
}

const documentsFilters = [
    { field: "numNotice", label: 'Numéro', type: 'text' },
    { field: "typeLibelle", label: "Type", type: 'select', options: [
        { value: null, label: "Tous" },
        { value: "Proposition de loi" , label: "Proposition de loi" },
        { value: "Projet de loi", label: "Projet de loi" },
        { value: "Proposition de résolution", label: "Proposition de résolution" },
        { value: "Rapport", label: "Rapport" },
        { value: "Rapport d'information", label: "Rapport d'information" },
        { value: "Avis", label: "Avis" },
        { value: "Accord international", label: "Accord international" }
    ]}
];

export const documentData = {
    key: "documents",
    name: "Documents",
    filters: documentsFilters,
    fields: documentsFields,
    icon: <DocumentIcon width={20} height={20} />
}