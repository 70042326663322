import { formatDate, formatOrgane, formatLabel } from "../formatters";
import { BooleanField } from "../components/BooleanField";
import { ReunionIcon } from "@logora/parliament.icons.regular_icons";

const formatMotif = (field, item) => {
    return item.resumeOdj || item.convocationOdj || item.objet || "-"
}

const formatCompteRendu = (field, item) => {
    return <BooleanField condition={item.compteRenduRefUid !== null} />
}

const formatBooleen = (field, item) => {
    return <BooleanField condition={item[field]} />
}

const reunionsFields = {
    'timestampDebut': { label: 'Date', field: 'timestampDebut', format: formatDate, sort: "timestampDebut", defaultSort: true },
    'organeReunionRef': { label: 'Organe', field: 'organeReunionRef', include: "organeReunionRef", format: formatOrgane },
    'motif': { label: 'Motif de l\'événement', field: 'motif', format: formatMotif }, // TODO : formatMotif (voir notion)
    'nombreParticipants': { label: 'Nombre de participants', field: 'nombreParticipants' },
    'compteRenduRefUid': { label: 'Compte-rendu', field: 'compteRenduRefUid', sort: 'compteRenduRefUid', format: formatCompteRendu },
    'etat': { label: 'État', field: 'etat', sort: 'etat', format: formatLabel },
    'captationVideo': { label: 'Vidéo', field: 'captationVideo', sort: 'captationVideo', format: formatBooleen },
}

const reunionsFilters = [
    {
        field: "etat", label: 'État', type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "Confirmé", label: 'Confirmé' },
            { value: "Annulé", label: 'Annulé' },
            { value: "Supprimé", label: 'Supprimé' },
            { value: "Eventuel", label: 'Éventuel' }
        ]
    },
    {
        field: "captationVideo", label: 'Vidéo', type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' }
        ]
    }
];

export const reunionData = {
    key: "reunions",
    name: "Réunions",
    includes: ["compteRenduRef"],
    filters: reunionsFilters,
    fields: reunionsFields,
    icon: <ReunionIcon width={20} height={20} />
}