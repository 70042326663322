import { formatOrgane, formatActeur, formatDate } from "../formatters";
import { SpeechIcon } from "@logora/parliament.icons.regular_icons";

const interventionsFields = {
    'dateSeance': { label: 'Date', field: "dateSeance", include: "debatRef", sort: "dateSeance", defaultSort: true, format: formatDate },
    'acteurRef': { label: 'Auteur', field: 'acteurRef', include: 'acteurRef', format: formatActeur },
    'texte': { label: 'Texte', field: "texte"},
}

const interventionsFilters = [
    { field: "uid", label: 'UID', type: 'text' },
    { field: "codeGrammaire", label: "Type", type: "text", hidden: true, defaultValue: "PAROLE_GENERIQUE" }
];

export const interventionData = {
    key: "interventions",
    name: "Interventions",
    filters: interventionsFilters,
    fields: interventionsFields,
    icon: <SpeechIcon width={20} height={20} />,
    rowRedirect: (item) => `/parliament/reunions/${item.debatRef.reunionRefUid}#intervention_${item.ordreAbsoluSeance}`
}